import 'dotenv/config';

const apiUrl = process.env.REACT_APP_apiUrl ? process.env.REACT_APP_apiUrl : "https://api.cyfrowa-gmina.dvl.p.lodz.pl/api/";
const maintenance = process.env.MAINTENANCE;

const defaultActiveLanguage = 'pl';

const roles = {
  ADMIN: 'admin',
  MODERATOR: 'moderator',
  VERIFIER: 'cppc',
  BENEFICIARY: 'beneficiary',
  CONTROLLER: 'controller',
  INSPECTOR: 'inspector',
  INSPECTOR_MASTER: 'master',
  STATEMENT_VERIFIER: 'verifier',
  PUBLIC_PROCUREMENT_VERIFIER: 'public_procurement_verifier',
  STATEMENT_APPROVER: 'approver',
};

const siteConfig = {
  siteName: 'Cyfrowa gmina',
  siteIcon: 'ion-flash',
  footerText: '© 2021 Cyfrowa gmina',
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

export { apiUrl, defaultActiveLanguage, roles, siteConfig, themeConfig, maintenance };
